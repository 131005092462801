import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { Globe, ArrowLeftRight, InfoCircle } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Modal, Link, LoadingAnimation, MessageBar, SearchBar, Table } from 'app/components';
import { searchViewAsMerchants } from 'app/store/actions/merchant';
import { searchViewAsVendors, setVendorSearchString } from 'app/store/actions/vendor';
import { setViewAs } from 'app/store/actions/user';
import { searchViewAsMerchantSelector, searchViewAsMerchantLoadingSelector, searchViewAsMerchantErrorsSelector } from 'app/store/selectors/merchant';
import { searchViewAsVendorDataSelector, searchViewAsVendorsLoadingSelector, searchViewAsVendorsErrorsSelector } from 'app/store/selectors/vendor';
import { currentUserSelector, viewAsSelector } from 'app/store/selectors/user';
import './index.scss';

const ViewAsMenu = ({ userData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showViewAsMenu, setShowViewAsMenu] = useState(false);
  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [hoverText, setHoverText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const merchantsData = useSelector(searchViewAsMerchantSelector);
  const merchantsLoading = useSelector(searchViewAsMerchantLoadingSelector);
  const merchantsErrorsFound = useSelector(searchViewAsMerchantErrorsSelector);

  const vendorsData = useSelector(searchViewAsVendorDataSelector);
  const vendorsLoading = useSelector(searchViewAsVendorsLoadingSelector);
  const vendorsErrorsFound = useSelector(searchViewAsVendorsErrorsSelector);

  const currentUser = useSelector(currentUserSelector);
  const viewAs = useSelector(viewAsSelector);

  // This useEffect is used to combine merchant and vendor data into a single array that will be displayed in search results...
  useEffect(() => {
    if (showViewAsMenu && (!merchantsLoading && !vendorsLoading)) {
      const vendorsFormatted = vendorsData?.filter(v => v.id !== currentUser?.attributes?.entityId?.[0]).map(item => (
        item.vendorName !== null && item.vendorName !== undefined ?
          { value: `f-${item.id}`, label: `${item.name} (${item.vendorName})` } :
          { value: `v-${item.id}`, label: item.name }
      )) || [];

      const merchantsFormatted = merchantsData?.merchants?.filter(m => m.id !== currentUser?.attributes?.entityId?.[0]).map(merchant => (
        merchant.isParent ?
          { value: `p-${merchant.id}`, label: merchant.name || merchant.legalBusinessName } :
          { value: `m-${merchant.id}`, label: merchant.name || merchant.legalBusinessName }
      )) || [];

      // do some sorting...
      let combinedEntities = [];
      if (userData?.superAdmin) {
        combinedEntities = [...merchantsFormatted, ...vendorsFormatted].sort((a, b) => a?.label?.localeCompare(b.label));
      } else if (userData?.merchant) {
        combinedEntities = merchantsFormatted.sort((a, b) => a?.label?.localeCompare(b.label));
      } else if (userData?.vendor) {
        combinedEntities = vendorsFormatted.sort((a, b) => a?.label?.localeCompare(b.label));
      }
      setEntities(combinedEntities);
      setIsProcessing(false);
    }
  }, [vendorsData, merchantsData, merchantsLoading, vendorsLoading, userData, showViewAsMenu]);

  useEffect(() => {
    // run this on opening the view as, to preload some data...
    if (showViewAsMenu) {
      onSearchStringUpdated('');
    }
  }, [showViewAsMenu]);

  // perform a search for merchants and vendors...
  const searchAllFunc = async (searchTerm) => {
    setIsProcessing(true);
    setEntities([]);
    searchMerchantsFunc(searchTerm);
    searchVendorsFunc(searchTerm);
  }

  // perform a search for merchants ONLY...
  const searchMerchantsFunc = async (searchTerm) => {
    setIsProcessing(true);
    setEntities([]);
    dispatch(searchViewAsMerchants({
      searchString: `name=${searchTerm}`,
      currentPage: 1,
      pageSize: 250,
      sortBy: 'name',
      disableXAccessHeader: true
    }));
  }

  // perform a search for vendors ONLY...
  const searchVendorsFunc = async (searchTerm) => {
    setIsProcessing(true);
    setEntities([]);
    dispatch(setVendorSearchString(searchTerm));
    dispatch(searchViewAsVendors({
      searchString: searchTerm,
      currentPage: 1,
      pageSize: 250,
      sortBy: 'Name',
      disableXAccessHeader: true
    }));
  }

  const onSearchStringUpdated = (searchString) => {
    if (userData?.superAdmin) {
      searchAllFunc(searchString);
    } else if (userData?.merchant) {
      searchMerchantsFunc(searchString);
    } else if (userData?.vendor) {
      searchVendorsFunc(searchString);
    }
  }

  const viewAsApply = () => {
    // we need to splice off the first 2 characters to determine if it's a merchant, parent merchant, vendor or facility
    const entityType = selectedEntity?.slice(0, 2);
    const entityId = selectedEntity?.slice(2);

    let entity = null;

    switch (entityType) {
      case 'm-':
        entity = merchantsData.merchants.find(merchant => merchant.id === entityId);
        entity = JSON.parse(JSON.stringify(entity));
        entity.userType = 'merchant';
        entity.userTypePrefix = 'm-';
        break;

      case 'p-':
        entity = merchantsData.merchants.find(merchant => merchant.id === entityId);
        entity = JSON.parse(JSON.stringify(entity));
        entity.userType = 'parent-merchant';
        entity.userTypePrefix = 'p-';
        break;

      case 'v-':
        entity = vendorsData.find(item => item.id === entityId);
        entity = JSON.parse(JSON.stringify(entity));
        entity.userType = 'vendor';
        entity.userTypePrefix = 'v-';
        break;

      case 'f-':
        entity = vendorsData.find(item => item.id === entityId);
        entity = JSON.parse(JSON.stringify(entity));
        entity.userType = 'facility';
        entity.userTypePrefix = 'f-';
        break;
      default:
        break;
    }

    dispatch(setViewAs({ data: entity }));
    setShowViewAsMenu(false);

    reloadPageSafely();
  }

  const reloadPageSafely = () => {
    // TEMP solution to reload the page after view as is applied...
    setTimeout(() => {
      navigate(0);
    }, 100);
  }

  const handleMouseEnter = (entValue) => {
    switch (entValue?.slice(0, 2)) {
      case 'm-':
        setHoverText('Merchant');
        break;
      case 'p-':
        setHoverText('Parent Merchant');
        break;
      case 'v-':
        setHoverText('Vendor');
        break;
      case 'f-':
        setHoverText('Facility');
        break;
      default:
        break;
    }
  };

  const handleMouseLeave = () => {
    setHoverText('');
  };

  const searchPlaceholder = `Search for a ${userData?.merchant ? 'Merchant' : userData?.vendor ? 'Vendor' : 'Merchant or Vendor'}`;

  return (
    <div className="view-as-menu">
      <div
        className="view-as-menu-content"
        onClick={() => setShowViewAsMenu(!showViewAsMenu)}
      >
        <div className="view-as-menu-icon">
          <Globe />
        </div>
        <div className="view-as-menu-text">
          <div className="view-as-menu-title">Viewing As</div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 1000, hide: 300 }}
            overlay={<Tooltip id="tooltip-top">{viewAs?.name || `${currentUser?.firstName} ${currentUser?.lastName}`}</Tooltip>}
          >
            <div className="view-as-menu-user">{viewAs?.name || `${currentUser?.firstName} ${currentUser?.lastName}`}</div>
          </OverlayTrigger>
        </div>
        <div className="arrow-icon">
          <ArrowLeftRight />
        </div>
      </div>
      {showViewAsMenu &&
        createPortal(
          <Modal
            className="view-as-modal"
            secondaryButtonLabel="Cancel"
            secondaryButtonOnClick={() => {
              setShowViewAsMenu(false);
              setSelectedEntity(null);
              setEntities([]);
            }}
            primaryButtonLabel="Apply"
            primaryButtonVariant="primary"
            primaryButtonOnClick={viewAsApply}
            primaryButtonEnabled={!(vendorsErrorsFound || merchantsErrorsFound || !selectedEntity) && !merchantsLoading && !vendorsLoading}
            onClose={() => setShowViewAsMenu(null)}
            isOpen={showViewAsMenu}
          >
            {vendorsErrorsFound || merchantsErrorsFound ? (
              <MessageBar color="yellow">
                An error occurred while fetching data
              </MessageBar>
            ) : (
              <>
                <div className="view-as-modal-header">
                  <div className="header-title">View As</div>
                  {!(merchantsLoading || vendorsLoading || isProcessing) && viewAs && (
                    <div className="header-action">
                      <Link
                        label="Clear"
                        onClick={() => {
                          dispatch(setViewAs({ data: null }));
                          setShowViewAsMenu(false);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className='view-as-search'>
                  <SearchBar
                    searchPlaceholderText={searchPlaceholder}
                    onSearchStringUpdated={onSearchStringUpdated}
                    debounceDelay={500}
                    disabled={false}
                    allowSearchParams={false}
                  />
                  <Table className='view-as-table'>
                    <tbody>
                      {(merchantsLoading || vendorsLoading || isProcessing) ? (
                        <LoadingAnimation fullscreen={false} />
                      ) : entities.length === 0 ? (
                        <tr className="no-results">
                          <td>No results found</td>
                        </tr>
                      ) : (
                        entities.map((ent, pos) =>
                          <tr className={`view-as-table-item ${selectedEntity === ent.value ? 'selected' : ''}`}
                            key={`${ent.value}${pos}`}
                            onClick={() => setSelectedEntity(ent.value)}
                            onMouseEnter={() => handleMouseEnter(ent.value)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <td><span>{ent.label}</span></td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  {hoverText && (
                    <div className="hover-text">
                      <InfoCircle />
                      {hoverText}
                    </div>
                  )}
                </div>
              </>
            )}
          </Modal>,
          document.body
        )}
    </div>
  );
};

export default ViewAsMenu;