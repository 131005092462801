import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string } from 'yup';
import {
  userDetailsSelector,
  userDetailsLoadingSelector,
  userDetailsErrorsSelector,
  rolesWithPermissionsDataSelector,
  rolesWithPermissionsLoadingSelector,
  rolesWithPermissionsErrorsSelector,
  viewAsSelector,
  currentUserSelector
} from 'app/store/selectors/user';
import { getUserDetails, createUser, updateUser, getRolesWithPermissions, requestForgotPassword } from 'app/store/actions/user';
import { searchMerchantsSelector, searchMerchantsLoadingSelector, searchMerchantsErrorsSelector, bulkGetMerchantsSelector } from 'app/store/selectors/merchant';
import { vendorsLoadingSelector, vendorsErrorsSelector, searchVendorDataSelector, vendorsBulkDataSelector, bulkGetFacilitiesSelector } from 'app/store/selectors/vendor';
import { Button, Card, LoadingAnimation, Input, Dropdown, ButtonIcon, Checkbox, MessageBar, DataPoint, IconData } from 'app/components';
import { ArrowLeft, EyeFill, EyeSlashFill, Envelope } from 'react-bootstrap-icons';
import { formatUsersPermissionsName, formatUsersResourceName, formatUsersRolesName } from '../utils';
import { usePermission, isMerchant, isSuperAdmin, isVendor } from 'app/permissions';
import { bulkGetMerchants, searchMerchants } from 'app/store/actions/merchant';
import { bulkGetFacilities, getVendorsBulk, searchVendors } from 'app/store/actions/vendor';
import MultiSelectSearch from 'app/components/MultiSelectSearch';
import NotificationsCard from '../NotificationsCard';
import './index.scss';

const UserDetails = () => {
  const { userId } = useParams();

  const notificationsCardRef = useRef();
  const submitFormRef = useRef(null);
  const [editMode, setEditMode] = useState(false);
  const [organizationsVisible, setOrganizationsVisible] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [entities, setEntities] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState("");
  const [hoverTooltip, setHoverTooltip] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector(userDetailsSelector);
  const userDetailsLoading = useSelector(userDetailsLoadingSelector);
  const userDetailsErrorsFound = useSelector(userDetailsErrorsSelector);
  const currentUserType = { superAdmin: isSuperAdmin(), merchant: isMerchant(), vendor: isVendor() };

  const allRolesWithPermissions = useSelector(rolesWithPermissionsDataSelector);
  const allRolesWithPermissionsLoading = useSelector(rolesWithPermissionsLoadingSelector);
  const allRolesWithPermissionsErrorsFound = useSelector(rolesWithPermissionsErrorsSelector);

  const merchantsData = useSelector(searchMerchantsSelector);
  const merchantsLoading = useSelector(searchMerchantsLoadingSelector);
  const merchantsErrorsFound = useSelector(searchMerchantsErrorsSelector);
  const associatedMerchants = useSelector(bulkGetMerchantsSelector);

  const vendorsData = useSelector(searchVendorDataSelector);
  const vendorsLoading = useSelector(vendorsLoadingSelector);
  const vendorsErrorsFound = useSelector(vendorsErrorsSelector);
  const associatedVendors = useSelector(vendorsBulkDataSelector);
  const associatedFacilities = useSelector(bulkGetFacilitiesSelector);

  const viewAs = useSelector(viewAsSelector);
  const currentUser = useSelector(currentUserSelector);

  // get user permissions
  const canManageUsers = usePermission('users', 'users_create');

  //
  // This useEffect is used to combine merchant and vendor data into a single array
  // that will be displayed in search results...
  //
  useEffect(() => {
    if (vendorsData || merchantsData) {
      const vendorsFormatted = vendorsData?.filter(v => v.id !== currentUser?.attributes?.entityId?.[0]).map(item => (
        item.vendorName !== null && item.vendorName !== undefined ?
          { value: `f-${item.id}`, label: `${item.name} (${item.vendorName})` } :
          { value: `v-${item.id}`, label: item.name }
      )) || [];

      const merchantsFormatted = merchantsData?.merchants?.filter(m => m.id !== currentUser?.attributes?.entityId?.[0]).map(merchant => (
        merchant.isParent ?
          { value: `p-${merchant.id}`, label: merchant.name || merchant.legalBusinessName } :
          { value: `m-${merchant.id}`, label: merchant.name || merchant.legalBusinessName }
      )) || [];

      // do some sorting...
      let combinedEntities = [];
      if (currentUserType?.superAdmin) {
        combinedEntities = [...merchantsFormatted, ...vendorsFormatted].sort((a, b) => a?.label?.localeCompare(b.label));
      } else if (currentUserType?.merchant) {
        combinedEntities = merchantsFormatted.sort((a, b) => a?.label?.localeCompare(b.label));
      } else if (currentUserType?.vendor) {
        combinedEntities = vendorsFormatted.sort((a, b) => a?.label?.localeCompare(b.label));
      }
      setEntities(combinedEntities);
    }
  }, [vendorsData, merchantsData]);

  useEffect(() => {
    if (userId && (!userDetails || userDetails.id !== userId)) {
      dispatch(getUserDetails(userId));
    }

    dispatch(getRolesWithPermissions());
  }, []);

  useEffect(() => {
    // this is edit user mode, so we need to fetch some details about user...
    if (userDetails) {
      const idsForSearch = [...new Set(userDetails?.attributes?.entityId?.concat(userDetails?.attributes?.associatedIds || []))];

      switch (userDetails?.attributes?.entity?.[0]) {
        case 'merchant':
          dispatch(bulkGetMerchants(idsForSearch));
          break;
        case 'vendor':
        case 'facility':
          dispatch(getVendorsBulk(idsForSearch));
          dispatch(bulkGetFacilities(idsForSearch));
          break;
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      switch (userDetails?.attributes?.entity?.[0]) {
        case 'merchant':
          setSelectedOrganizations(associatedMerchants?.bulk?.filter(m => m.status === 200)?.map(m => m.response?.name)?.join(', '));
          break;
        case 'vendor':
        case 'facility':
          {
            const vendors = associatedVendors?.bulk?.filter(v => v.status === 200)?.map(v => v.response?.vendor?.name)?.join(', ') || '';
            const facilities = associatedFacilities?.bulk?.filter(f => f.status === 200)?.map(f => f.response?.facility?.name)?.join(', ') || '';
            setSelectedOrganizations([vendors, facilities].filter(Boolean).join(', '));
          }
          break;
      }
    }
  }, [associatedMerchants, associatedVendors, associatedFacilities]);

  useEffect(() => {
    // apply some rules if ViewAs is active...
    if (viewAs) {
      // for merchants and facilities, we have to hide organizations selection, because by default
      // that merchant or facility is selected...
      setOrganizationsVisible(!(viewAs.userType === 'merchant' || viewAs.userType === 'facility'));
    }
  }, [viewAs]);

  useEffect(() => {
    // run this on opening the view as, to preload some data...
    onSearchStringChange('');
  }, []);

  // perform a search for merchants and vendors...
  const searchAllFunc = async (searchTerm) => {
    searchMerchantsFunc(searchTerm);
    searchVendorsFunc(searchTerm);
  }

  // perform a search for merchants ONLY...
  const searchMerchantsFunc = async (searchTerm) => {
    dispatch(searchMerchants({
      searchString: `name=${searchTerm}`,
      currentPage: 1,
      pageSize: 250,
      sortBy: 'name'
    }));
  }

  const onSearchStringChange = (searchString) => {
    switch (viewAs?.userType) {
      case 'merchant':
      case "parent-merchant":
        searchMerchantsFunc(searchString);
        break;
      case "vendor":
      case 'facility':
        searchVendorsFunc(searchString);
        break;
      default:
        searchAllFunc(searchString);
        break;
    }
  }

  // perform a search for vendors ONLY...
  const searchVendorsFunc = async (searchTerm) => {
    dispatch(searchVendors({
      searchString: searchTerm,
      currentPage: 1,
      pageSize: 250,
      sortBy: 'Name'
    }));
  }

  const onUserCreated = (newUserId) => {
    if (notificationsCardRef.current) {
      notificationsCardRef.current.saveChanges(newUserId);
    }
    navigate(`/admin/users`);
  }

  const onUserUpdated = () => {
    navigate(`/admin/users`);
  }

  const displayHeader = () => {
    if (userId) {
      return editMode ? 'Edit User' : (userDetails?.firstName || "") + " " + (userDetails?.lastName || "");
    } else {
      setEditMode(true);
      return 'Create User';
    }
  }

  const displayAccountDetails = () => {
    const roles = userDetails?.roles?.map(r => r?.name).join(', ');
    return <DataPoint title="Roles" data={roles || '-'} />
  }

  const displayPermissions = () => {
    let roleAndPermissions = userDetails?.roles[0];

    return (
      <>
        <div className="account-permissions-header" hidden={userDetails?.roles[0]?.id ? false : true}>
          <div>Selected Permissions</div>
        </div>
        <div className="account-permissions-details">
          {roleAndPermissions?.permissionSet?.map(permission => (
            <div key={roleAndPermissions?.id + permission.resourceId}>
              <div className="account-permissions-container">
                <div className="account-permissions-title">{formatUsersResourceName(permission.resourceName)} </div>
                <div className="account-permissions-checkbox">
                  <Checkbox disabled={true} size='medium' checked={permission.associatedPermissions.length == permission.availablePermissions.length}
                  />
                </div>
              </div>
              <div className="account-permissions">
                {permission?.availablePermissions?.map(available =>
                (
                  <div key={roleAndPermissions?.id + permission.resourceId + available.name} className="account-permissions-line" >
                    <div className="account-permissions-title">{available.description?.length ? available.description : formatUsersPermissionsName(available.name)}</div>
                    <div className="account-permissions-checkbox">
                      <Checkbox disabled={true} size='medium' checked={permission.associatedPermissions?.map(ap => ap.name).includes(available.name)} />
                    </div>
                  </div>
                )
                )}</div>
            </div>
          ))}
        </div>
      </>)
  }

  const handleOrganizationSelection = (e) => {
    // apply some rules for organizations selection...
    // 1. can't select parent merchant if merchant is already selected and vice versa
    // 2. can't select vendor if facility is already selected and vice versa
    // 3. only one vendor or parent merchant can be selected
    // 4. can't mix merchant with vendor or facility and vice versa
    if (e.length > 0) {
      const entityType = e[e.length - 1].value.slice(0, 2);
      const entitiesMap = e.map(se => se.value.slice(0, 2));

      switch (entityType) {
        case 'm-':
          if (entitiesMap.includes('p-') || entitiesMap.includes('v-') || entitiesMap.includes('f-')) {
            return;
          } else {
            setSelectedEntities(e);
          }
          break;
        case 'p-':
        case 'v-':
          if (e.length > 1) {
            return;
          } else {
            setSelectedEntities(e);
          }
          break;
        case 'f-':
          if (entitiesMap.includes('v-') || entitiesMap.includes('m-') || entitiesMap.includes('p-')) {
            return;
          } else {
            setSelectedEntities(e)
          }
          break;
      }
    } else {
      setSelectedEntities(e);
    }
  }

  const organizationsView = () => {
    return (
      <div className='organizations-view'>
        <MultiSelectSearch
          label="Select Organization (Optional)"
          items={entities}
          selected={selectedEntities}
          onItemHover={(e) => {
            if (e?.value) {
              switch (e.value.slice(0, 2)) {
                case 'm-':
                  setHoverTooltip('Merchant');
                  break;
                case 'p-':
                  setHoverTooltip('Parent Merchant');
                  break;
                case 'v-':
                  setHoverTooltip('Vendor');
                  break;
                case 'f-':
                  setHoverTooltip('Facility');
                  break;
                default:
                  setHoverTooltip('');
              }
            } else {
              setHoverTooltip('');
            }
          }}
          onChange={handleOrganizationSelection}
          onSearchStringChange={onSearchStringChange}
          onClearSelection={() => setSelectedEntities([])}
          hoverTooltip={hoverTooltip}
          showHoverTooltip={true}
          placeholder="Select Organization"
        />
      </div>
    )
  }

  return (
    <div className="user-details">
      {(
        <Formik
          innerRef={submitFormRef}
          enableReinitialize
          initialValues={{
            initialAttributes: userDetails?.attributes || {},
            firstName: userDetails?.firstName || '',
            lastName: userDetails?.lastName || '',
            email: userDetails?.email || '',
            password: userDetails?.password || '',
            initialRole: userDetails?.roles[0]?.id || '',
            role: userDetails?.roles[0]?.id || '',
            roles: userDetails?.roles[0]?.permissionSet?.map(permission => ({
              "resourceId": permission.resourceId,
              "permissions": permission.associatedPermissions,
            })) || {},
            roleAndPermissions: userDetails?.roles[0] || allRolesWithPermissions?.filter(role => role.id == userDetails?.roles[0]?.id)[0],
          }}
          validationSchema={() =>
            object().shape({
              firstName: string().required('Enter a valid First Name'), // required
              lastName: string().required('Enter a valid Last Name'), // required
              email: string()
                .matches(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  'Enter a valid Email'
                ).required('Enter a valid Email'),
              password: string().when([], () => {
                return userId ? string().notRequired() : string().required('Enter a Password');
              }),
              role: string().required('Enter a Role'), // required
              subAdminId: string().when('role', {
                is: role =>
                  ['Parent Merchant Admin', 'Merchant Admin', 'Vendor Admin'].includes(
                    role
                  ),
                then: () => string().required(
                  'Required Field'
                ),
                otherwise: () => string().notRequired(),
              }),
            })
          }
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            if (userDetails) {
              // update existing user
              dispatch(updateUser({ userId: userDetails.id, values: { ...values, selectedEntities }, cb: onUserUpdated }));
              if (notificationsCardRef.current) {
                notificationsCardRef.current.saveChanges();
              }
            } else {
              // create new user
              dispatch(createUser({ values: { ...values, selectedEntities }, cb: onUserCreated }));
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            submitCount,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              {(userDetailsLoading || merchantsLoading || vendorsLoading || allRolesWithPermissionsLoading) && <LoadingAnimation />}
              <Card className="header-card">
                <div>
                  <div className="user-name">
                    <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/users/')} />
                    <div>{displayHeader()} </div>
                  </div>
                  <div>
                    {userDetails && !editMode && (
                      <IconData label={userDetails.email} icon={<Envelope />} />
                    )}
                  </div>
                </div>
                <div className="action-buttons">
                  {!editMode && userDetails && canManageUsers ? (
                    <div className="d-flex">
                      <Button
                        className="mx-2"
                        variant="secondary"
                        size="medium"
                        label={'Reset Password'}
                        onClick={() => dispatch(requestForgotPassword({ email: userDetails.email, isAdmin: true }))}
                      />
                      <Button
                        variant="primary"
                        size="medium"
                        label={'Edit User'}
                        onClick={() => setEditMode(true)}
                      />
                    </div>
                  ) : canManageUsers ? (
                    <div className="d-flex">
                      {userDetails && (
                        <Button
                          className="mx-2"
                          variant="secondary"
                          size="medium"
                          label={'Cancel'}
                          onClick={() => { resetForm(); setEditMode(false); }}
                        />)}
                      <Button
                        variant="primary"
                        size="medium"
                        label={userId ? 'Save Changes' : "Create User"}
                        onClick={() => submitFormRef.current && submitFormRef.current.handleSubmit()}
                      />
                    </div>
                  ) : null}
                </div>
              </Card>

              {(userDetailsErrorsFound) && (
                <MessageBar color="yellow">
                  User Data failed to load.  Refresh the page to try again.
                </MessageBar>
              )}
              {(vendorsErrorsFound) && (
                <MessageBar color="yellow">
                  Vendor Data failed to load.  Refresh the page to try again.
                </MessageBar>
              )}
              {(merchantsErrorsFound) && (
                <MessageBar color="yellow">
                  Merchant Data failed to load.  Refresh the page to try again.
                </MessageBar>
              )}
              {(allRolesWithPermissionsErrorsFound) && (
                <MessageBar color="yellow">
                  Roles Data failed to load.  Refresh the page to try again.
                </MessageBar>
              )}

              {(userDetails || !userId) && (
                <>
                  {!editMode && userDetails && (
                    <>
                      {selectedOrganizations && (
                        <Card className={`user-details-card ${editMode ? 'edit-mode' : ''}`}>
                          <Card.Header>
                            User Details
                          </Card.Header>
                          <Card.Body>
                            <div className="user-details-inputs view-mode">
                              <DataPoint title="Selected Organizations" data={selectedOrganizations} />
                            </div>
                          </Card.Body>
                        </Card>
                      )}

                      <NotificationsCard editMode={false} userId={userId} ref={notificationsCardRef} />

                      <Card className={`user-details-card account-details ${editMode ? 'edit-mode' : ''}`}>
                        <Card.Header>
                          Account Details
                        </Card.Header>
                        <Card.Body>
                          <div className="account-details-inputs">
                            {displayAccountDetails()}
                          </div>
                          <div>
                            {displayPermissions()}
                          </div>
                        </Card.Body>
                      </Card>
                    </>
                  )}

                  {editMode && (userDetails || !userId) && (
                    <Card className={`user-details-card ${editMode ? 'edit-mode' : ''}`}>
                      <Card.Header>
                        User Details
                      </Card.Header>
                      <Card.Body>
                        <div className="user-details-inputs">
                          <Input
                            label="First Name"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            placeholder="First Name"
                            errorMessage={submitCount > 0 && errors.firstName}
                          />
                          <Input
                            label="Last Name"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            placeholder="Last Name"
                            errorMessage={submitCount > 0 && errors.lastName}
                          />
                          <Input
                            label="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            placeholder="Email"
                            errorMessage={submitCount > 0 && errors.email}
                          />
                          {!userId && (
                            <Input
                              label="Password"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              placeholder="Password"
                              type={showPassword ? "text" : "password"}
                              icon={showPassword ? <EyeSlashFill /> : <EyeFill />}
                              onIconClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              errorMessage={submitCount > 0 && errors.password}
                            />
                          )}
                        </div>
                        {organizationsVisible && <div className='user-details-inputs view-mode'>{organizationsView()}</div>}
                      </Card.Body>
                    </Card>)}

                  {editMode && <NotificationsCard editMode={true} userId={userId} ref={notificationsCardRef} />}

                  {editMode && (userDetails || !userId) && (
                    <Card className={`user-details-card account-details ${editMode ? 'edit-mode' : ''}`}>
                      <Card.Header className="account-details-header">
                        Account Details
                      </Card.Header>
                      <Card.Body>
                        <div className="account-details-inputs">
                          <Dropdown
                            label="Role"
                            name="role"
                            value={values.role}
                            onChange={(e) => {
                              let roleAndPermissions = allRolesWithPermissions?.filter(role => role.id == e.target.value.toString())[0];
                              setFieldValue('roleAndPermissions', roleAndPermissions);
                              setFieldValue('role', roleAndPermissions?.id);
                              let roles = roleAndPermissions?.permissionSet?.map(permission => ({
                                "resourceId": permission.resourceId,
                                "permissions": permission.associatedPermissions,
                              }));
                              setFieldValue('roles', roles);
                              handleChange(e);
                            }}
                            options={allRolesWithPermissions?.map(role => ({ value: role.id, label: formatUsersRolesName(role?.name) })) || []}
                            errorMessage={submitCount > 0 && errors.role}
                          />
                        </div>
                        <div className="account-permissions-header" hidden={values.role ? false : true}>
                          <div>Select Permissions</div>
                          <div>Add Permissions</div>
                        </div>
                        <div className="account-permissions-details">
                          {values.roleAndPermissions?.permissionSet?.map(permission => (
                            <div key={values.roleAndPermissions?.id + permission.resourceId}>
                              <div className="account-permissions-container">
                                <div className="account-permissions-title">{formatUsersResourceName(permission.resourceName)} </div>
                                <div className="account-permissions-checkbox">
                                  <Checkbox size='medium' checked={permission.associatedPermissions.length == permission.availablePermissions.length}
                                    onChange={(e) => {
                                      if (e) {
                                        permission.associatedPermissions = permission.availablePermissions;
                                      } else {
                                        permission.associatedPermissions = [];
                                      }
                                      let roles = values.roleAndPermissions?.permissionSet?.map(permission => ({
                                        "resourceId": permission.resourceId,
                                        "permissions": permission.associatedPermissions,
                                      }));
                                      setFieldValue('roles', roles);
                                    }} />
                                </div>
                              </div>
                              <div className="account-permissions">
                                {permission?.availablePermissions?.map(available =>
                                (
                                  <div key={values.roleAndPermissions?.id + permission.resourceId + available.name} className="account-permissions-line" >
                                    <div className="account-permissions-title">{available.description?.length ? available.description : formatUsersPermissionsName(available.name)}</div>
                                    <div className="account-permissions-checkbox">
                                      <Checkbox size='medium' checked={permission.associatedPermissions?.map(ap => ap.name).includes(available.name)} onChange={() => {
                                        if (permission.associatedPermissions?.map(ap => ap.name).includes(available.name)) {
                                          permission.associatedPermissions = permission.associatedPermissions.filter(q => q.name !== available.name);
                                        } else {
                                          permission.associatedPermissions.push(available);
                                        }
                                        let roles = values.roleAndPermissions?.permissionSet?.map(permission => ({
                                          "resourceId": permission.resourceId,
                                          "permissions": permission.associatedPermissions,
                                        }));
                                        setFieldValue('roles', roles);
                                      }} />
                                    </div>
                                  </div>
                                )
                                )}</div>
                            </div>))}
                        </div>
                      </Card.Body>
                    </Card>
                  )}
                </>
              )}
            </form>
          )}
        </Formik>
      )}
    </div >
  )
}

export default UserDetails;